// const baseURL = "http://owb.bjszhgx.net" // 测试
const baseURL = "https://owb.bjszhgx.cn" // 正式

// const systemNumber = "761837369880287" // 测试系统编号
// const systemNumber = "2403181010100001" // 昆明食用菌研究所
const systemNumber = "2407311310290001" // 职业技能鉴定中心
// const systemNumber = "2407311304100001" // 北京商业机械研究所
// const systemNumber = "2408091729140001" // 中合金诺认证中心

export default {
  baseURL,
  systemNumber
}