<template>
  <div id="app">
    <header-com></header-com>
    <router-view />
    <footer-com></footer-com>
  </div>
</template>

<script>
import HeaderCom from '@/components/header/HeaderCom'
import FooterCom from '@/components/footer/FooterCom'

export default {
  name: "App",
  data() {
    return {
      
    }
  },
  components: {
    HeaderCom,
    FooterCom
  },
  methods: {
    
  }
}
</script>

<style>
/* @import url("./assets/css/kmsbase.css"); */
@import url("./assets/css/jdbase.css");
/* @import url("./assets/css/bcmbase.css"); */
/* @import url("./assets/css/jnbase.css"); */

</style>
